.container {
  width: 15rem;
  height: 22rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 15px;
  color: white;
  // background: var(--token-card-bg-color);
  // background-color: black;

  border-radius: 8px;
  // border: 0.15rem solid var(--token-card-border-color);
  backdrop-filter: blur(5px);
  // border: 1px solid #080808;
  user-select: none;
  &:hover {
    background-color: #19218c;
  }
}

.img {
  width: 3rem;
  height: 3rem;
}

.priceUp {
  color: #06b470;
}

.priceDown {
  color: #f74e2c;
}

.priceTrendImg {
  width: 1rem;
  height: 1rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.priceUpImg {
  background: url('../../assets/image/price/up.png');
}

.priceDownImg {
  background: url('../../assets/image/price/down.png');
}

.price {
  font-family: 'Source Sans 3';
  font-weight: 600;
}

.chart {
  width: 15rem;
  height: 6rem;
  overflow: hidden;
  position: relative;
}

.innerChartContainer {
  position: absolute;
  top: -3rem;
  width: 15rem;
  height: 15rem;
}
