.content {
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  max-width: 892px;
  margin: 0 auto;
  justify-content: center;
}

.title {
  font-size: 24px;
  color: #333;
  line-height: 24px;
  margin-bottom: 16px;
}

.card {
  background: #080808;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  padding: 32px;
  color: white;
  align-items: center;
  flex: 1;
}

.button {
  height: 48px !important;
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  border-radius: 10px !important;
  padding: 0 24px !important;
}
